import React from 'react'
import nf from '../images/404.png'
import TemplatePage from './TemplatePage';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { handleClick } from '../components/Utils';




export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className='container-nf h-screen flex-row'>

      <TemplatePage
        padding={'pt-32 md:pt-28'}
        className={'container'}
        title={"Market Airco2 | 404 Not Found"}
        image={nf}>

        <div className='flex-row'>
          <div className='flex justify-center'>

            <img alt='notFound' src={nf} width={'750px'}></img>
          </div>

          <div className='w-full flex flex-row justify-center text-airco2-filter-content text-md'>
            <div className='px-2'>
              <span> {t("auth.dirige")}</span>
              <Link onClick={handleClick} to={`/`}>
                <span style={{ color: '#8DD4BD', fontStyle: 'italic', fontWeight: 'bold' }}> home </span>


              </Link>
              <span> {t("auth.siExiste")}</span>
            </div>



          </div>
        </div>
      </TemplatePage>
    </div>
  )
}
