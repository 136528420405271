
import PaginaEstatica from "../../components/PaginaEstatica";
import Imagen from '../../images/auth-image-register.webp';
import { useTranslation } from 'react-i18next';
import TemplatePage from "../TemplatePage";


function AvisoLegal() {
    const { t } = useTranslation();


    return (
        <TemplatePage
            //  className={'container'}
            padding={'pt-26'}
            title={"Market Airco2 | " + t("aLegal.aLegal")}
            image={Imagen}>


            <PaginaEstatica imagen={Imagen} title={t("aLegal.aLegal").toUpperCase()} >
                <div className='container mb-5 mt-5 text-airco2-general'>

                    <div className='container mt-2'>


                        <h1 className='font-semibold mb-2'>{t("aLegal.responsable")}</h1>
                        <p><span className='font-semibold mb-2'>{t("aLegal.titular")}:</span>  AIRCO2 ESPACIO FINTECH, S.L.</p>
                        <p><span className='font-semibold mb-2'>CIF:</span> B-67677930</p>
                        <p><span className='font-semibold mb-2'>{t("aLegal.tel")}:</span> <a href="tel:+34689794117" className='' aria-label='redirige a mandar un mail' >(+34) 689 794 117</a></p>

                        <p className='flex gap-1'>
                            <span className='font-semibold mb-2'>E-mail: </span>
                            <a href="mailto:lopd@airco2.eu" className='' aria-label='redirige a mandar un mail' >
                                lopd@airco2.eu
                            </a>
                        </p>

                    </div>
                    <div className=' container mt-4'>
                        <p>{t("aLegal.txtLegal1")}<a href="mailto:lopd@airco2.eu" className='' aria-label='redirige a mandar un mail' >
                            lopd@airco2.eu
                        </a></p>
                        <p>{t("aLegal.txtLegal2")}</p>
                        <p>{t("aLegal.txtLegal3")}</p>
                        <p>{t("aLegal.txtLegal4")}</p>
                    </div>

                    <div className=' container mt-4'>
                        <h1 className='font-semibold mb-2'>{t("aLegal.condicionesGenerales")}</h1>
                        <p>{t("aLegal.txtCondiciones1")}</p>
                        <p>{t("aLegal.txtCondiciones2")}</p>
                        <p>{t("aLegal.txtCondiciones3")}</p>


                    </div>
                    <div className=' container mt-4'>
                        <h1 className='font-semibold mb-2 mt-4'>{t("aLegal.responsabilidad")} </h1>
                        <p>{t("aLegal.propietarioLista")}</p>
                        <ul className='px-4 my-1'>
                            <li type='circle'>{t("aLegal.propLista1")}</li>
                            <li type='circle'>{t("aLegal.propLista2")}</li>
                            <li type='circle' >{t("aLegal.propLista3")}</li>

                        </ul>
                        <p>{t("aLegal.proptxt")}</p>
                        <p>{t("aLegal.proptxt2")}</p>


                    </div>
                    <div className=' container mt-4'>
                        <h1 className='font-semibold mb-2'>{t("aLegal.pIntelectual")}</h1>
                        <p>{t("aLegal.pIntelectualtxt1")}</p>
                        <p>{t("aLegal.pIntelectualtxt2")}</p>




                    </div>




                </div>




            </PaginaEstatica>
        </TemplatePage>

    )
}

export default AvisoLegal;