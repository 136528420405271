import React from 'react'
import perfilok from '../images/perfilok.svg'
import perfilko from '../images/perfilko.svg'
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../context/authContext';
import axios from 'axios';
import { useState, useEffect } from 'react';
import TemplatePage from './TemplatePage';


export default function StripeResponse({ status }) {
  const { t } = useTranslation();
  const auth = useAuthContext();

  // FETCH para saber si tiene usuario o no en stripe
  const [stripeInfo, setStripeInfo] = useState({
    submited: false,
    chargesEnabled: false,
    isFetching: false
  });

  useEffect(() => {

    const fetchStatusStripeProvider = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MP_STRIPE}`, {

          headers: {
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        });
        setStripeInfo({ submited: response.data.stripeSubmited, chargesEnabled: response.data.stripeChargesEnabled, isFetching: true });

      } catch (exception) {
        setStripeInfo({ submited: false, chargesEnabled: false, isFetching: true });
      }
    };

    if (status) {
      fetchStatusStripeProvider();
    } else {
      setStripeInfo({ submited: false, chargesEnabled: false, isFetching: true });
    }

  }, []);



  const handlerGetUrlConnectStripeAccount = () => {
    const data = {
    }

    const fetchGetUrlConnectStripeAccount = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_MP_STRIPE}`, data,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${auth.getToken()}`
            }
          })
          .then((response) => {
            window.location.href = response.data;


          }, (error) => {
            console.log(error);
          });
      } catch (exception) {
        console.log(exception);
      }
    };
    fetchGetUrlConnectStripeAccount();
  }


  const handlerGetUrlStripeDashboardAccount = () => {


    const fetchGetUrlStripeDashboardAccount = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_MP_STRIPE_DASHBOARD}`,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${auth.getToken()}`
            }
          })
          .then((response) => {
            window.location.href = response.data;


          }, (error) => {
            console.log(error);
          });
      } catch (exception) {
        console.log(exception);
      }
    };
    fetchGetUrlStripeDashboardAccount();
  }



  return (
    <TemplatePage
      padding={'p-cabecera'}
      className={'container'}
      title={t("meta.stripeConnect.title")}>
  

      <div className='text-center text-airco2-filter-content mx-auto' >
        {!stripeInfo.isFetching ?
          <h1>{t("orders.cargando")}</h1>
          :
          <div className='divContainer'>
            {

              stripeInfo.submited ?
                <h1 className=" text-xl md:text-2xl  mb-6">{t("pay.usuarioOkStripe")}✨</h1>
                :
                <h1 className=" text-xl md:text-2xl mb-6">{t("pay.usuariofallo")}</h1>
            }
            <div className='flex-row'>

              <div className='flex justify-center'>
                {

                  stripeInfo.submited ?
                    <img alt='perfilok' src={perfilok} width={'380'}></img>
                    :
                    <img alt='perfilko' src={perfilko} width={'380'}></img>
                }
              </div>

              <div className='w-full flex flex-row justify-center'>
                <div className='px-2 flex  mt-1 text-xl gap-2 flex-wrap'>
                  {
                    stripeInfo.submited ?
                      <span className='flex gap-1'>
                        <span> {t("pay.acceder")}</span>
                        <a href="#" onClick={() => handlerGetUrlStripeDashboardAccount()} className='text-airco2-principal'>{t("pay.perfilStripe")} </a>
                        <span> {t("pay.oaTu")}</span>
                        <a href='https://www.airco2.earth/forest/dashboardmontes' className='text-airco2-principal'>{t("pay.dashboard")}</a>.
                      </span>
                      :
                      <span className='flex gap-1'>

                        <span> {t("pay.dirigeStripe")}</span>
                        <a href="#" onClick={() => handlerGetUrlConnectStripeAccount()} className='text-airco2-principal'>{t("pay.perfilStripe")} </a>
                        <span> {t("pay.vuelveintentar")}</span>
                      </span>
                  }




                </div>

              </div>

            </div>
          </div>
        }
      </div>
    </TemplatePage>
  )
}
