import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/account/Sidebar';
import ProfileForm from "../../components/account/profile/ProfileForm";
import TemplatePage from '../TemplatePage';

function Profile() {

const { t } = useTranslation();
    return (
        < div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"  style={{ backgroundColor: '#ebebe9' }}>

            <TemplatePage
                padding={'pt-[12vh]'}
                title={t("meta.profile.title")}
                >

                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"style={{ backgroundColor: '#ebebe9' }}>

                    {/* Content */}
                    <div className="container bg-white shadow-lg rounded-sm mb-8">
                        <div className="flex flex-col md:flex-row md:-mr-px">
                            <Sidebar></Sidebar>
                            <ProfileForm />
                        </div>
                    </div>
                </div>
            </TemplatePage>
        </div >
    );
}

export default Profile;