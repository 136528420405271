import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import circle from '../images/location.svg';
import forest from '../images/iconTrees.svg';
import owner from '../images/userProvider.svg';
import { formatThousands } from '../utils/Utils';
import { handleClick } from './Utils';

import { productUrl } from "./Utils";
import { providerUrl } from "./Utils";
import { speciesImage } from './Utils';

export default function CardComponent({
  v, categories, styles
}) {

  const { t } = useTranslation();

  function getColor(name) {
    return categories.categoriesData.find(element => element.name === name)?.color;
  }

  function removeAccents(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const forestTypeLowerCase = removeAccents(v.forestType)
    .toLowerCase()
    .replace(/\s/g, '');


 

  const backgroundImage = speciesImage(forestTypeLowerCase);



  return (



    <Card key={v.id} style={{ border: 'none' }} className={` text-airco2-general  ${styles} `}  >

      <div className='relative card-img-mk ml-3 mr-3'>
        <Link onClick={handleClick} to={productUrl(v)} className='text-airco2-general'>


          {(v.stockCredits === null || v.stockCredits <= 0 || v.stockCredits === "") && v.active
            && <div className='absolute w-full h-full' style={{ backgroundColor: 'rgb(110 110 110 / 80%)', height: '22rem', top: '16px', color: 'white' }}>
              <div className='flex justify-center items-center h-full'><span className='text-xl tracking-widest		uppercase'>{t("detail.sinstock")}</span></div>
            </div>}
          {(!v.active)
            && <div className='absolute w-full h-full' style={{ backgroundColor: 'rgb(110 110 110 / 80%)', height: '22rem', top: '16px', color: 'white' }}>
              <div className='flex justify-center items-center h-full'><span className='text-xl tracking-widest		uppercase'>{t("detail.proximamente")}</span></div>
            </div>}

          {v.productImageDtoList.length === 0 ?

            <div className='mt-3 card-img-catalogo' style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: "center", backgroundSize: "cover" }}>
            </div>
            :
            v.productImageDtoList.map((y, index) =>

              y.principal === true ?
                <div key={index} className=' mt-3 card-img-catalogo' style={{ backgroundImage: `url(${y.path})`, backgroundPosition: "center", backgroundSize: "cover" }}>

                </div>
                :
                ""


            )}
          <div className=' text-center card-text-mk absolute pl-2 pr-2 rounded-sm w-full text-sm' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '22rem', top: '16px', color: 'white' }}>
            <span style={{ position: 'relative', top: '100px' }}>
              {v.summary}
            </span>
          </div>

        </Link>

        <div className=" absolute" style={{ top: '16px', left: '0' }}>
          {v.category !== null &&
            <div className={`  text-xs font-medium text-gray-100 bg-opacity-40 text-center px-2 py-0.5 category-card-catalogo`} style={{ backgroundColor: `${getColor(v.category)}` }}>
              <svg className=" inline-block mb-1" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3" />
              </svg>
              <span className='text-sm inline-block' data-tip data-for="registerTip">{v.category}</span>
            </div>
          }
        </div>
        {v.forestType != null && v.forestType.length !== 0 ?
          <div className=" absolute " style={{ bottom: 0, right: 0 }}>
            <div className=" inline-block text-xs  text-white bg-airco2-principal   text-center px-2 py-0.5">
              <img alt='arbol-icono' src={forest} width={'22px'} className='mx-1 inline-block'></img>
              <span className='text-sm inline-block' data-tip data-for="registerTip">{v.forestType}</span>
            </div>
          </div>
          :
          ""
        }
      </div>


      <Card.Body style={{ height: '123px' }}>

        <Card.Text className='flex'>
          <Col xs={10} >
            <div className=''>
              <Link onClick={handleClick} to={productUrl(v)} className='text-airco2-general'>
                <div className='font-bold flex text-lg'>
                  {v.name.length <= 25 ?
                    <h2 style={{ maxHeight: '27px' }}>{v.name}</h2>
                    :
                    <h2 style={{ maxHeight: '27px' }}>{v.name.slice(0, 20)}...</h2>
                  }
                </div>
              </Link>



            </div>

            <div className='mt-1' >
              <Link onClick={handleClick} to={providerUrl(v)} className='text-airco2-general  text-sm'>
                <span className='flex gap-1'>
                  <img alt='circulo-icono' src={owner} width={'16px'} ></img>
                  <p className='mx-1'>{v.provider}</p>

                </span>
              </Link>
              <span className='flex'>
                <img alt='circulo-icono' src={circle} width={'16px'} ></img>
                <a href={`https://maps.google.com/?q=${v.latitude},${v.longitude}`} aria-label='redirige a googleMaps' target='_blank' rel='noopener noreferrer'
                  className='text-airco2-general  text-sm'>
                  <span className='mx-2'> {v.city}, {v.state}</span>
                </a>
              </span>
            </div>

          </Col>

          <div className='absolute iconosCard'>
            {v.sustainabilityGoalDtoList.length > 0 &&
              // <Card.Title className='flex justify-end' >
    
                  <ul className=' flex flex-wrap gap-2 flex-row-reverse'>
                  {v.sustainabilityGoalDtoList.map((y, index) =>
                    <li key={index} className=''>
                      <button data-toggle="tooltip" data-placement="left" title={y.name}>
                        <img alt='iconosostenibilidad' src={y.image} width={'22px'} />
                      </button>
                    </li>
                  )}
                </ul>

           
                
              // </Card.Title>
              
              }


          </div>

        </Card.Text>



        {!v.active ? <div className='flex justify-center items-center mt-1 bg-airco2-principal text-white'>
          <span className='text-base tracking-wider		uppercase'>{t("detail.proximamente")}</span>
        </div> :
          (v.stockCredits === null || v.stockCredits <= 0 || v.stockCredits === "") ?
            <div className='flex justify-center items-center mt-1 bg-airco2-general text-white'>
              <span className='text-base tracking-wider		uppercase'>{t("detail.sinstock")}</span>
            </div>
            :
            <ul className='flex justify-between mt-1'>
              <li className=' text-sm text-airco2-principal font-bold'>
                {formatThousands(v.price)}<span className='font-normal'>€/{t("detail.tonelada").toLowerCase()}</span>
              </li>
              <li className=' text-sm text-airco2-secondary font-bold'>
                {formatThousands(v.stockCredits - v.reservedCredits)}<span className='font-normal'>{t("detail.toneladaD").toLowerCase()}</span>
              </li>
            </ul>
        }

      </Card.Body>


    </Card >


  )
}
