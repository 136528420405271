import React from 'react';
import { useTranslation } from 'react-i18next';
import BannerForest from '../components/BannerForest';
import HomeBanner from '../components/HomeBanner';
import HomeStory from '../components/HomeStory';
import PopUpForestal from '../components/PopUpForestal';
import Featured from '../components/featured';
import HomeAbout from '../components/homeAbout';
import metatag_image from './../images/Slide01.webp';
import TemplatePage from './TemplatePage';
import BannerMetodologia from './BannerMetodologia';


export default function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <TemplatePage h1="Airco2"
        title={t("meta.title")}
        desc={t("meta.description")}
        image={metatag_image}>

        <HomeBanner></HomeBanner>
        <PopUpForestal></PopUpForestal>
        <div className='container pb-5'>
          <Featured></Featured>
          <HomeAbout></HomeAbout>
          <HomeStory></HomeStory>
         
        </div>
        {/* <BannerMetodologia></BannerMetodologia> */}
        <BannerForest></BannerForest>

      </TemplatePage>
    </div>
  )
}
