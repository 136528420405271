import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../context/authContext'
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function ShoppingBannerSummary({
  data,
  datos,
  envQ,
}) {

  const { t } = useTranslation();
  const dataShopping = data
  const auth = useAuthContext();
  const navigate = useNavigate();

  const [terminos, setTerminos] = useState(false);
  const [revender, setrevender] = useState(false);
  let quantity = dataShopping ? dataShopping.map((x) => x.quantity) : [];
  let quantityTotal = quantity.reduce((a, b) => a + b, 0);
  let [quantityAct, setQuantity] = useState(quantity)
  let [quantityTotalAct, setQuantityTotal] = useState(quantityTotal)


  useEffect(() => {
    setQuantity(quantityAct)
    setQuantityTotal(quantityTotalAct)



  }, [envQ])
  const [orderList, setOrderList] = useState({
    data: [],
    isFetching: false
  });
  const handlerOrder = () => {
    const fetchOrder = async () => {
      try {

        const response = await axios.get(` ${process.env.REACT_APP_MP_CART_ORDER}`, {

          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        });
        setOrderList({ data: response.data, isFetching: true });

      } catch (exception) {
        console.log(exception);
        setOrderList({ data: [], isFetching: true });
      }
    };

    fetchOrder();
    navigate(`/finishorder/${datos.id}`)




  }

  return (
    <div className='border-t border-gray-200 mx-2'>
      {/* {dataShopping === undefined ? ""
        :
 
        
            
          <div className='flex justify-end mt-2 gap '>
          {data.length > 1 ?
            <span>{t("shop.compraEstos")} {data.length} {t("shop.emisionesNetas")} xx %</span>
            :
            <span>{t("shop.compraEstos")} {t("shop.emisionesNetas")} xx %</span>
          }
          <span className=' w-[8rem] md:w-[8rem]'> {t("modalShop.total")}:{quantityTotal}
          {t("shop.toneladasShop")}
          </span>

          <span className=' w-[9rem] md:w-[8rem]'>{(datos.total).toFixed(2)}€</span>
        </div>

      } */}

      <div className="flex justify-center mt-4">

        <div className='flex-row'>
          <label className="flex items-center">
            <input type="checkbox"
              className="form-checkbox "
              name="consent"
              style={{ border: '1px solid white' }}
              onChange={e => { setTerminos(e.target.checked) }}
            />

            <span className="text-xs ml-2 "> {t("shop.confirmoLeido")} 
            <a className='text-airco2-general' href={'/terms'} target={"_blank"} rel='noreferrer'><span className=''>{t("shop.terminosServicios")}</span>  </a> <span className="text-airco2-secondary"> *</span>.</span>
          </label>


          <label className="flex items-center">
            <input type="checkbox"
              className="form-checkbox"
              name="consent"
              style={{ border: '1px solid white' }}
              // onChange={e => { setConsent(e.target.checked), handleOnchange() }} 
              onChange={e => { setrevender(e.target.checked) }}

            />

            <span className="text-xs ml-2"> {t("shop.noRevender")}.</span>
          </label>

        </div>
      </div>



      <div className=' flex justify-center gap-4 mt-2 flex-wrap' >
        {terminos && revender === true ?


          <button onClick={() => { handlerOrder() }} className='btn mt-2 pt-2 pb-2 mb-1 px-5 py-1 bg-airco2-principal  text-white text-xs'>
           
            {t("shop.confirmarPagar")}
       

          </button>
          :
          <button disabled className='btn  mt-2 pt-2 pb-2 mb-1 px-5 py-1 bg-airco2-general text-white text-xs'>
            {t("shop.confirmarPagar")}</button>
        }
       
      </div>


<div>

</div>
  



    </div>

  )
}
