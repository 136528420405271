import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatThousands } from '../utils/Utils';
import { useAuthContext } from '../context/authContext';
import { useShoppingContext } from '../context/shoppingContext';
import Toast2 from '../utils/Toast2';
import axios from 'axios';
import { useTranslation } from 'react-i18next'
import { handleClick } from './Utils';
import { SolicitarInfo } from './SolicitarInfo';
const Styles = styled.div`
  align-items: center;
  color: black;
  margin: 1rem; 


  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: black;
    outline: none;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border-radius: 50%;
      appearance: none;
      width: 18px;
      height: 18px;
      background: white;
      cursor: pointer;
      outline: 4px solid #8DD4BD;

    }
    &::-moz-range-thumb {
      width: 18px;
      height: 18px;
      background: #4f46e5;
      cursor: pointer;.map
      outline: 4px solid #333;

    }
  }
`;

export default function CardBuy({
  productsid


}) {


  const [slider, setSlider] = useState(0);

  const [disabled, setDisabled] = useState(true);
  const [charging, setCharging] = useState(false);

  const handleOnChange = (event) => {
    const newValue = event.target.value;
    setSlider(newValue);
    if (newValue > 0) {
      setDisabled(false);
    } else {
      setDisabled(true)
    }

  }

  const handleInputNumberChange = (event) => {
    const inputValue = event.target.value;
    let newValue;
    
    if (inputValue === "" || isNaN(inputValue)) {
      newValue = 0;
    } else {
      newValue = parseInt(inputValue);
      if (newValue < 0) {
        newValue = 0;
      } else if (newValue > prAvailable) {
        newValue = prAvailable;
      }
    }
    if(newValue>0 && newValue <= prAvailable){
      setDisabled(false);
    }else{
      setDisabled(true);
    }
    
    setSlider(newValue);
  };
  const { t } = useTranslation();


  const prPrice = productsid.price
  const prId = productsid.id
  const prAvailable = parseInt(productsid.stockCredits - productsid.reservedCredits);

  const auth = useAuthContext();
  const [co2tonsSeleccionadas, setCo2tonsSeleccionadas] = useState(0);

  const shoppingContext = useShoppingContext();


  const [eurtopay, setEurtopay] = useState(0);
  useEffect(() => {
    const toneladasParaCompensar = slider;
    setCo2tonsSeleccionadas(toneladasParaCompensar);

    if (prPrice !== undefined) {
      setEurtopay(toneladasParaCompensar * prPrice);

    }

  }, [slider])
  const [toast2SuccessOpen, setToast2SuccessOpen] = useState(false);
  const [toast2WarningOpen, setToast2WarningOpen] = useState(false);


  const handlerSubmit = () => {

    const data = {

      "idProduct": prId,
      "quantity": slider,
      "price": prPrice
    }
    const fetchUpdateShopping = async () => {
      setCharging(true)
      try {
        axios.post(`${process.env.REACT_APP_MP_SHOP}`, data,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${auth.getToken()}`
            }
          })
          .then((response) => {
            setCharging(false)
            if (response.status === 200) {
              setToast2SuccessOpen(true)
              setTimeout(() => {
                setToast2SuccessOpen(false);
              }, "3000")

            } else {
              setToast2WarningOpen(true)
              setTimeout(() => {
                setToast2WarningOpen(false);
              }, "3000")

            }

            shoppingContext.updateCart(response.data);

          }, (error) => {
            console.log(error);
          });
      } catch (exception) {
        console.log(exception);
      }
    };
    fetchUpdateShopping();





  }

  return (
    <div className='text-airco2-general'>

      <label htmlFor="default-range" className="block text-xs font-medium text-center dark:text-white" style={{ marginBottom: "-10px" }}>
        <span >{t("cardBuy.compensar")} <b>{formatThousands(slider)} t</b> {t("cardBuy.de")}{formatThousands(prAvailable.toFixed(2))}  </span>
        <div className='mt-2' style={{ marginBottom: "12px"}}><b><input type='number' className='form-input bg-white w-20 py-1' style={{borderColor: "#eaeaea" }} min={0} max={prAvailable} value={slider}  onChange={handleInputNumberChange}></input></b></div>
        </label>
      <Styles>
        <input type="range" min={0} max={prAvailable} value={slider} className="slider" step={1} onChange={handleOnChange} />
        <span className=' float-left text-xs mb-2' style={{ marginTop: "-8px" }}>0t &nbsp;</span>
        <span className=" float-right text-xs mb-2" style={{ marginTop: "-8px" }}>&nbsp;{formatThousands(prAvailable.toFixed(2))}t</span>
      </Styles>

      <div className='flex justify-center w-full'>
        <span className='text-sm '> {t("cardBuy.vasCompensar")} <b>{formatThousands(slider)} t</b> {t("cardBuy.por")} <b>{formatThousands(eurtopay.toFixed(2))}</b> € + IVA </span>
      </div>


      <div className="mb-2 mt-4 ">
        {auth.isAuthenticated === true ?
          <>
            {productsid.onSale &&
              <div className='flex justify-center2'>
                <button className="btn  w-full bg-airco2-principal  text-white" onClick={handlerSubmit} disabled={disabled}>
                  {charging ? <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-blue-600" role="status">
                    <span className="visually-hidden">{t("account.address.getAddress")}</span>
                  </div> : t("detail.addCarrito")}
                </button></div>}

            {!productsid.onSale && <div className='text-center'><SolicitarInfo></SolicitarInfo></div>}
          </>
          :
          <>
          {productsid.onSale === true ?
          <>
            <div className='flex justify-center flex-wrap mt-3 gap-2'>
              <Link onClick={handleClick} to={"/register"} className='w-full md:w-fit'>
                <button className=" btn w-full bg-airco2-principal text-white">{t("cardBuy.registro")}</button></Link>
              <Link onClick={handleClick}
                className='w-full md:w-fit' to={{
                  pathname: "/login",
                  search: "?originUrl=" + window.location.pathname,
                }} > <button className=" btn w-full bg-airco2-principal text-white">{t("cardBuy.login")}</button></Link>
            </div>
            <div className="text-xs  italic text-center mt-2">{t("cardBuy.addLogin")}<a className="underline hover:no-underline" href={'/terms'} target={"_blank"} rel='noreferrer'>{t("cardBuy.terms")}</a></div>
            </>
            : <div className='text-center'><SolicitarInfo></SolicitarInfo></div>
              }
          </>
        }

      </div>

      <Toast2 className='text-center' type="success" open={toast2SuccessOpen} setOpen={setToast2SuccessOpen}>
        {t("cardBuy.okCarrito")}
      </Toast2>
      <Toast2 className='text-center' type="warning" open={toast2WarningOpen} setOpen={setToast2WarningOpen}>

        {t("cardBuy.koCarrito")}
      </Toast2>

    </div>



    // </div>
  )
}
