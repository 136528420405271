import { useTranslation } from 'react-i18next';
import PaginaEstatica from "../../components/PaginaEstatica";
import Imagen from '../../images/auth-image-login.webp';
import TemplatePage from "../TemplatePage";


function TerminosCondicionesMP() {
    const { t } = useTranslation();


    return (<div>

        <TemplatePage
            // className={'container'}
            padding={'pt-26'}
            title={"Market Airco2 | " + t("footer.terms")}
            image={Imagen}>
            <PaginaEstatica imagen={Imagen}
                title={t("footer.terms")}>
                <div className='container mb-5 mt-5 text-airco2-general'>

                    <div className=' container mt-4'>
                        <h1 className='font-semibold mb-2'>{t("terms.title")}</h1>
                        <p className="pb-2">{t("terms.paragraph1")}</p>
                        <p className="pb-2">{t("terms.paragraph2")}</p>
                        <p className="pb-2">{t("terms.paragraph3")}</p>
                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.ownerTitle")}</h2>
                        <p className="pb-2">{t("terms.ownerDescription")}</p>
                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.objectTitle")}</h2>
                        <p className="pb-2">{t("terms.objectDescription1")}</p>
                        <p className="pb-2">{t("terms.objectDescription2")}</p>
                        <p className="pb-2">{t("terms.objectDescription3")}</p>
                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.userRequirementsTitle")}</h2>
                        <ul className='px-4 my-1'>
                            <li type='circle' className='mb-2'>{t("terms.userRequirementsDescription1")}</li>
                            <li type='circle' className='mb-2'>{t("terms.userRequirementsDescription2")}</li>
                        </ul>
                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.userTypesTitle")}</h2>
                        <p className="pb-2">{t("terms.userTypesTitleIntto")}</p>
                        <ul className='px-4 my-1'>
                            <li type='circle' className='mb-2'>{t("terms.userTypesDescription1")}</li>
                            <li type='circle' className='mb-2'>{t("terms.userTypesDescription2")}</li>
                            <li type='circle' className='mb-2'>{t("terms.userTypesDescription3")}</li>
                        </ul>
                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.paymentsTitle")}</h2>
                        <p className="pb-2">{t("terms.paymentsDescription1")}</p>
                        <p className="pb-2">{t("terms.paymentsDescription2")}</p>
                        <ul className='px-4 my-1'>
                            <li type='circle' className='mb-2'>{t("terms.paymentsDescription3")}</li>
                            <li type='circle' className='mb-2'>{t("terms.paymentsDescription4")}</li>
                            <li type='circle' className='mb-2'>{t("terms.paymentsDescription5")}</li>
                            <li type='circle' className='mb-2'>{t("terms.paymentsDescription6")}</li>
                            <li type='circle' className='mb-2'>{t("terms.paymentsDescription7")}</li>
                            <li type='circle' className='mb-2'>{t("terms.paymentsDescription8")}</li>
                            <li type='circle' className='mb-2'>{t("terms.paymentsDescription9")}</li>
                        </ul>

                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.ownerRegistrationTitle")}</h2>
                        <p className="pb-2">{t("terms.ownerRegistrationDescription1")}</p>
                        <ul className='px-4 my-1'>
                            <li type='circle' className='mb-2'>{t("terms.ownerRegistrationList1")}</li>
                            <li type='circle' className='mb-2'>{t("terms.ownerRegistrationList2")}</li>
                            <li type='circle' className='mb-2'>{t("terms.ownerRegistrationList3")}</li>
                            <li type='circle' className='mb-2'>{t("terms.ownerRegistrationList4")}</li>
                            <li type='circle' className='mb-2'>{t("terms.ownerRegistrationList5")}</li>
                            <li type='circle' className='mb-2'>{t("terms.ownerRegistrationList6")}</li>
                        </ul>

                        <p className="pb-2">{t("terms.ownerRegistrationDescription2")}</p>
                        <p className="pb-2">{t("terms.ownerRegistrationDescription3")}</p>
                        <p className="pb-2">{t("terms.ownerRegistrationDescription4")}</p>

                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.carbonCreditProcedureTitle")}</h2>
                        <p className="pb-2">{t("terms.carbonCreditProcedureDescription1")}</p>
                        <p className="pb-2">{t("terms.carbonCreditProcedureDescription2")}</p>
                        <ul className='px-4 my-1'>
                            <li type='circle' className='mb-2'>{t("terms.carbonCreditProcedureList1")}</li>
                            <li type='circle' className='mb-2'>{t("terms.carbonCreditProcedureList2")}</li>
                            <li type='circle' className='mb-2'>{t("terms.carbonCreditProcedureList3")}</li>
                            <li type='circle' className='mb-2'>{t("terms.carbonCreditProcedureList4")}</li>
                            <li type='circle' className='mb-2'>{t("terms.carbonCreditProcedureList5")}</li>
                            <li type='circle' className='mb-2'>{t("terms.carbonCreditProcedureList6")}</li>
                        </ul>
                        <p className="pb-2">{t("terms.carbonCreditProcedureDescription3")}</p>
                        <p className="pb-2">{t("terms.carbonCreditProcedureDescription4")}</p>

                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.withdrawalRightTitle")}</h2>
                        <p className="pb-2">{t("terms.withdrawalRightDescription1")}</p>
                        <p className="pb-2">{t("terms.withdrawalRightDescription2")}</p>
                        <p className="pb-2">{t("terms.withdrawalRightDescription3")}</p>

                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.accountClosureTitle")}</h2>
                        <p className="pb-2">{t("terms.accountClosureDescription1")}</p>
                        <p className="pb-2">{t("terms.accountClosureDescription2")}</p>


                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.changesTitle")}</h2>
                        <p className="pb-2">{t("terms.changesDescription1")}</p>
                        <p className="pb-2">{t("terms.changesDescription2")}</p>
                        <p className="pb-2">{t("terms.changesDescription3")}</p>

                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.defaultTitle")}</h2>
                        <p className="pb-2">{t("terms.defaultDescription")}</p>

                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.miscellaneousTitle")}</h2>
                        <ul className='px-4 my-1'>
                            <li type='circle' className='mb-2'>{t("terms.miscellaneousDescription1")}</li>
                            <li type='circle' className='mb-2'>{t("terms.miscellaneousDescription2")}</li>
                            <li type='circle' className='mb-2'>{t("terms.miscellaneousDescription3")}</li>
                            <li type='circle' className='mb-2'>{t("terms.miscellaneousDescription4")}</li>
                            <li type='circle' className='mb-2'>{t("terms.miscellaneousDescription5")}</li>
                        </ul>

                        <h2 className='font-semibold mt-4 mb-2'>{t("terms.governingLawTitle")}</h2>
                        <p className="pb-2">{t("terms.governingLawDescription1")}</p>
                        <p className="pb-2">{t("terms.governingLawDescription2")}</p>
                    </div>
                </div>
            </PaginaEstatica>
        </TemplatePage>
    </div>)
}

export default TerminosCondicionesMP;