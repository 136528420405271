import Aos from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sticky from 'sticky-js';
import './App.css';
import './css/style.scss';

import { useTranslation } from 'react-i18next';
import RenderOnAnonymous from './auth/RenderOnAnonymous';
import RenderOnAuthenticated from './auth/RenderOnAuthenticated';
import { useAuthContext } from './context/authContext';
import Home from './pages/home';
import Carrito from './pages/Carrito';
import Catalogo from './pages/Catalogo';
import FinishOrder from './pages/FinishOrder';
import Loading from './pages/Loading';
import Map from './pages/Map';
import NotFound from './pages/NotFound';
import ProductDetail from './pages/ProductDetail';
import Provider from './pages/Provider';
import ResetPassword from './pages/ResetPassword';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Address from './pages/account/Address';
import Pedidos from './pages/account/Pedidos';
import Profile from './pages/account/Profile';

import StripeResponse from './pages/stripeResponse';
import PaymentResponse from './pages/PaymentResponse';
import AvisoLegal from './pages/legal/AvisoLegal';
import Privacidad from './pages/legal/Privacidad';

import Sitemap from "./sitemap";
import ForestList from './pages/ForestList';
import TerminosCondicionesMP from './pages/legal/TerminosCondicionesMP';






function App() {
  const { isReady } = useAuthContext();
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({
      once: true,
      // disable: 'phone',
      disable: false,
      duration: 700,
      easing: 'ease-out-cubic',
    });

    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
  });

  useEffect(() => {
    createSchemaBussines();
  }, []);

  const [schema, setSchema] = useState('');

  const createSchemaBussines = () => {
    const schemaStriucture = {
      "@context": "https://schema.org",
      "@type": "Organization",
      logo: "https://www.airco2.earth/static/media/AirCO2_02.693c172b.svg",
      url: "https://market.airco2.earth",
      name: t("meta.empresa"),
      address: {
        "@type": "PostalAddress",
        streetAddress: t("meta.direccion"),
        addressLocality: t("meta.localidad"),
        addressRegion: t("meta.region"),
        postalCode: t("meta.pc"),
        addressCountry: t("meta.country"),
      },

      email: t("meta.email"),
    }
    const schemaAsString = JSON.stringify(schemaStriucture, null, 2);
    setSchema(schemaAsString)

  }

  const googleTagManagerCode = 'G-DF96C64V3Z';

  return (

    <BrowserRouter>

      {process.env.REACT_APP_ENVIRONMENT === 'production' && <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerCode}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${googleTagManagerCode}');
          `}
        </script>
      </Helmet>}

      {process.env.REACT_APP_ENVIRONMENT === 'production' &&
        <Helmet>

          {`
            <!-- Google Tag Manager -->
            <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5TKSK4K');</script>
            <!-- End Google Tag Manager -->
         ` }
        </Helmet>

      }
      <Helmet>
        <script type="application/ld+json">{schema}</script>
      </Helmet>









      {!isReady &&
        <Loading />}

      {isReady &&
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/catalogo' element={<Catalogo />} />
          <Route path='/map' element={<Map />} />
          <Route path='/forest' element={<ForestList />} />
       


          <Route path='/login' element={<RenderOnAnonymous><Signin /></RenderOnAnonymous>} />
          <Route path='/register' element={<RenderOnAnonymous><Signup /></RenderOnAnonymous>} />
          <Route path='/reset-password' element={<RenderOnAnonymous><ResetPassword /></RenderOnAnonymous>} />


          <Route path='/product/:id' element={<ProductDetail></ProductDetail>} />
          <Route path='/product/:nombreDelProducto/:id' element={<ProductDetail></ProductDetail>} />
          <Route path='/provider/:id' element={<Provider></Provider>} />
          <Route path='/provider/:nombreDelProvider/:id' element={<Provider></Provider>} />

          <Route path='/stripe/connect/verified' element={<StripeResponse status={true}></StripeResponse>} />
          <Route path='/stripe/connect/retry' element={<StripeResponse status={false} ></StripeResponse>} />



          <Route path="/" element={<RenderOnAuthenticated />}>
            <Route path='/shopping' element={<Carrito />} />
            <Route path='/finishorder/:id' element={<FinishOrder />}></Route>
          </Route>


          <Route path="/account" element={<RenderOnAuthenticated />}>
            <Route path='/account/orders' element={<Pedidos />} />
            <Route path='/account/profile' element={<Profile />} />
            <Route path='/account/address' element={<Address />} />
          </Route>



          <Route path='paymentresponse' element={<PaymentResponse></PaymentResponse>}></Route>


          <Route path='/legal' element={<AvisoLegal></AvisoLegal>}></Route>
          <Route path='/privacy' element={<Privacidad></Privacidad>}></Route>
          <Route path='/terms' element={<TerminosCondicionesMP></TerminosCondicionesMP>}></Route>



          <Route path='/sitemap' element={<Sitemap></Sitemap>} />
          <Route path='*' element={<NotFound></NotFound>} />

        </Routes>

      }

    </BrowserRouter >


  );
}

export default App;
