import TemplatePage from "../TemplatePage";
import PaginaEstatica from "../../components/PaginaEstatica";
import { useTranslation } from 'react-i18next';
import Imagen from '../../images/auth-image-login.webp';


function Privacidad() {
  const { t } = useTranslation();


  return (<div>

    <TemplatePage
      // className={'container'}
      padding={'pt-26'}
      title={"Market Airco2 | " + t("cookies.politicaP")}
      image={Imagen}>
      <PaginaEstatica imagen={Imagen}
        title={t("cookies.politicaYcookies")}>
        {/* // texto={t("legal.privacidad")} */}

        <div className='container mb-5 mt-5 text-airco2-general'>
         
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/f8db383c-e828-4168-95fe-440b53ab9f34/cd.js" type="text/javascript" async></script>
        <div className='legaltext  container mt-4'>
          <h1 className='font-semibold mb-3  mt-2'> 1.{t("cookies.politicaP")}</h1>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-sm" style={{ backgroundColor: 'rgb(235, 235, 233,0.5)' }}>
              <tr className="flex flex-wrap md:table-row md:flex-no-wrap">
                <th className="w-full block md:w-auto md:table-cell py-2 px-3 ">
                  <div className="font-semibold text-center">{t("cookies.epigrafe")}</div>
                </th>
                <th className="w-full hidden md:w-auto md:table-cell py-2 px-3">
                  <div className="font-semibold text-center">{t("cookies.infoBasica")}</div>
                </th>
                <th className="w-full hidden md:w-auto md:table-cell py-2 px-3">
                  <div className="font-semibold text-center">{t("cookies.infoAdicional")}</div>
                </th>

                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm">
                {/* Row1 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left font-semibold">{t("cookies.responsable")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left font-semibold">AIRCO2 ESPACIO FINTECH, S.L.</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left flex flex-column ">
                      <span><span className='font-semibold'>{t("cookies.tel")}</span> :(+34) 689 794 117</span>
                      <span><span className='font-semibold'>{t("cookies.direccion")}</span> :Rúa Manuel Quiroga 11, 1º Izq – 36002,Pontevedra</span>
                      <span><span className='font-semibold'>E-mail</span>
                        <a href="mailto:lopd@airco2.eu" className='' aria-label='redirige a mandar un mail' >:lopd@airco2.eu</a></span>
                      <span><span className='font-semibold'>CIF</span> :B-67677930</span>

                    </div>
                  </td>

                </tr>
                {/* Row2 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left font-semibold ">{t("cookies.finalidad")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left flex flex-column">
                      <span className='font-semibold'>{t("cookies.finalidadtxt")}</span>
                      <span className=''>- {t("cookies.finalidadtxt2")}</span>
                      <span className=''>- {t("cookies.finalidadtxt3")}</span>
                      <span className=''>- {t("cookies.finalidadtxt4")}</span>
                    </div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left  flex flex-column">
                      <span className='font-semibold'>{t("cookies.plazos")}</span>
                      <span>{t("cookies.plazostxt")}</span>


                    </div>
                  </td>

                </tr>
                {/* Row3 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left font-semibold ">{t("cookies.baseLegitima")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left flex flex-column">
                      <span className='font-semibold'>{t("cookies.baseJuridica")}</span>
                      <span className=''>{t("cookies.bJtxt")}</span>
                    </div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left  flex flex-column">
                      <span className='font-semibold'>{t("cookies.obligacionDatos")}</span>
                      <span>{t("cookies.obligaciontxt")}</span>
                      <span>{t("cookies.obligaciontxt2")}</span>


                    </div>
                  </td>

                </tr>


                {/* Row4 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left font-semibold ">{t("cookies.datos")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left flex flex-column">

                      <span className=''> {t("cookies.datostxt")}</span>

                    </div>
                  </td>


                </tr>
                {/* Row5 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left font-semibold ">{t("cookies.derechos")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left flex flex-column">
                      <span className=''>{t("cookies.derechostxt")}</span>

                    </div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left  flex flex-column">

                      <span>- {t("cookies.derechostxt2")}</span>
                      <span>- {t("cookies.derechostxt3")}</span>
                      <span>- {t("cookies.derechostxt4")}</span>


                    </div>
                  </td>

                </tr>
                {/* Row6 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left font-semibold ">{t("cookies.datosDnd")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-left flex flex-column">

                      <span className=''> {t("cookies.datosClientes")}</span>

                    </div>
                  </td>


                </tr>

              </tbody>
            </table>

            <h1 className='font-semibold mb-2 mt-4 '> 2.{t("cookies.usoCookies")}</h1>
            <div className='legaltext  container mt-2'>
              <p>{t("cookies.politicaCookies")}</p>
              <p className='italic font-semibold'>{t("cookies.cookie")}</p>
              <p>{t("cookies.cookietxt")}</p>
              <p className='italic font-semibold'>{t("cookies.tipo")}</p>
              <p className='underline mb-1'>{t("cookies.permanencia")}</p>
              <p className=''>{t("cookies.perm1")}</p>
              <p className=''>{t("cookies.perm2")}</p>
              <p className='underline mb-1'>{t("cookies.fin")}</p>
              <p className=''>{t("cookies.finTecnicas")}</p>
              <p className=''>{t("cookies.finAnalisis")}</p>
              <p className='underline mb-1'>{t("cookies.procedencia")}</p>
              <p className=''>{t("cookies.propias")}</p>
              <p className=''>{t("cookies.terceros")}</p>

            </div>

            <h1 className='font-semibold text-center mt-6 mb-6'> {t("cookies.usadas")}</h1>
            <table className="table-auto w-full">
              {/* Table header */}
              <thead className="text-sm" style={{ backgroundColor: 'rgb(235, 235, 233,0.5)' }}>
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap">
                  <th className="w-full block md:w-auto md:table-cell py-2 px-3 ">
                    <div className="font-semibold text-center">Cookie</div>
                  </th>
                  <th className="w-full hidden md:w-auto md:table-cell py-2 px-3">
                    <div className="font-semibold text-center">{t("cookies.finalidad")}</div>
                  </th>
                  <th className="w-full hidden md:w-auto md:table-cell py-2 px-3">
                    <div className="font-semibold text-center">{t("cookies.duracion")}</div>
                  </th>
                  <th className="w-full hidden md:w-auto md:table-cell py-2 px-3">
                    <div className="font-semibold text-center">{t("cookies.descripcion")}</div>
                  </th>
                  <th className="w-full hidden md:w-auto md:table-cell py-2 px-3">
                    <div className="font-semibold text-center">{t("cookies.gestor")}</div>
                  </th>

                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm">
                {/* Row1 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">CookieConsent</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.tecnica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.360")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.plug")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">airco2.eu</div>
                  </td>

                </tr>
                {/* Row2 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">hs-message-is-open</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center"></div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.30m")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.widget")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center"></div>
                  </td>

                </tr>
                {/* Row3 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">__hssc</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.analitica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.30m")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.seguimiento")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center"></div>
                  </td>

                </tr>
                {/* Row4 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">__hsrc</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.analitica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.durante")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.hubspot")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center"></div>
                  </td>

                </tr>
                {/* Row5 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">__hstc</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.analitica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.180")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.cookiePrincipal")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">airco2.eu</div>
                  </td>

                </tr>
                {/* Row6 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">hubspotutk</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.analitica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.180")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.identidad")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">airco2.eu</div>
                  </td>

                </tr>
                {/* Row7 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">AUTH_SESSION_ID</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.tecnica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.durante")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.autenticar")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">keycloak.airco2.eu</div>
                  </td>

                </tr>
                {/* Row8 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">KEYCLOAK_3P_COOKIE_SAMESITE</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.tecnica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">1{t("cookies.minuto")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.cookies3")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">keycloak.airco2.eu</div>
                  </td>

                </tr>
                {/* Row9 */}
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 px-3 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">__cf_bm</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.tecnica")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.30m")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">{t("cookies.cdn")}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2 px-3">
                    <div className="text-center">hubspot.com</div>
                  </td>

                </tr>
              </tbody>
            </table>

            <h1 className='font-semibold mb-2 mt-4 '> 3.{t("cookies.habilitacion")}</h1>
            <div className='legaltext container'>

              <p>{t("cookies.funciones")}</p>
              <p className="mb-1">{t("cookies.permitir")}</p>
              <p>- Chrome: <a aria-label='redirige' href={'http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647  '} target="_blank" rel="noreferrer">http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a></p>
              <p>- Firefox: <a aria-label='redirige' href={'http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we '} target="_blank" rel="noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></p>
              <p>- Explorer: <a aria-label='redirige' href={'http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10    '} target="_blank" rel="noreferrer">http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10</a></p>
              <p>- Safari: <a aria-label='redirige' href={'http://support.apple.com/kb/ph5042  '} target="_blank" rel="noreferrer">http://support.apple.com/kb/ph5042 </a></p>
              <p>- Opera: <a aria-label='redirige' href={'http://help.opera.com/Windows/11.50/es-ES/cookies.html '} target="_blank" rel="noreferrer">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
              <p className="mt-1">{t("cookies.otros")}</p>


            </div>
            <h1 className='font-semibold mb-2 mt-4'> 4.{t("cookies.cambios")}</h1>
            <div className='legaltext  container '>
              <p>{t("cookies.cambiotxt")}</p>
            </div>



          </div>

        </div>
      </PaginaEstatica>
    </TemplatePage>
  </div>)
}

export default Privacidad;