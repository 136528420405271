import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import CardCatalogo from '../components/CardCatalogo'
import FiltroCatalogo from '../components/FiltroCatalogo'
import PaginationClassic from '../components/PaginationClassic'
import search from '../images/search.svg'
import TemplatePage from './TemplatePage'
import { useCountriesContext } from '../context/countriesContext'
import BannerForest from '../components/BannerForest'
import metatag_image from './../images/Slide01.webp'

export default function Catalogo() {
  const { t } = useTranslation();
  const countriesContext = useCountriesContext();


  const filtroData = JSON.parse(localStorage.getItem('filtro'));
 


  const [productsFetch, setProductsFetch] = useState({
    productsData: [],
    isFetching: false
  });
 
  const [productsFilter, setProductsFilter] = useState(filtroData?.data || productsFetch.productsData);





  useEffect(() => {
    if (localStorage.getItem('filtro') !== null) {
     
      applyFilter(filtroData?.data)
    }
  }, [productsFetch.productsData])
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setProductsFetch({ productsData: productsFetch.productsData, isFetching: false });
        const response = await axios.get(`${process.env.REACT_APP_MP_PRODUCTS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        });
        setProductsFetch({ productsData: response.data, isFetching: true });
        
        if (!filtroData?.data) {
          setProductsFilter(response.data);
         
        }
      } catch (exception) {
        setProductsFetch({ productsData: productsFetch.productsData, isFetching: false });
      }
    };
    fetchProducts();
  }, []);
  


  //Ordernar catálogo
  const [orderSelectValue, setOrderSelectValue] = useState()

  useEffect(() => {
    if (orderSelectValue === 'pxAscendente') {
      setProductsFilter([...productsFilter].sort((a, b) => {
        return a.price - b.price
      }));

    } else if (orderSelectValue === 'pxDescendente') {

      setProductsFilter([...productsFilter].sort((a, b) => {
        return b.price - a.price
      }));

    } else if (orderSelectValue === 'QDescendente') {

      setProductsFilter([...productsFilter].sort((a, b) => {
        return (b.stockCredits - b.reservedCredits) - (a.stockCredits - a.reservedCredits)

      }));
    } else if (orderSelectValue === 'QAscendente') {

      setProductsFilter([...productsFilter].sort((a, b) => {
        return (a.stockCredits - a.reservedCredits) - (b.stockCredits - b.reservedCredits)
      }))

    }

  }, [orderSelectValue])



  // PARTE FILTROS
  const [speciesList, setSpeciesList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [developmentList, setDevelopmentList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [estadosList, setEstadosList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {

    // Especies unicas y pasarle el check a falso

    if (localStorage.getItem('filtro') !== null) {
      setSpeciesList(filtroData?.data[1])

    } else {
      const speciesList = [];


      productsFetch.productsData.map((x) => {
        return x.forestType
      }).filter((elemento, index, self) => {
        return index === self.indexOf(elemento)
      }).map((v) => {
        speciesList.push({
          forestType: v,
          check: false
        }); return v
      })
      setSpeciesList(speciesList);
    }



    // Categorias unicas y pasarle el check a falso
    if (localStorage.getItem('filtro') !== null) {
      setCategoriesList(filtroData?.data[0])

    } else {
      const categoriesList = []
      productsFetch.productsData.map((x) => {
        return x.category
      }).filter((elemento, index, self) => {
        return index === self.indexOf(elemento);

      }).map((v) => {
        if (v === null) {
          v = t("cat.noCategory")
        }
        categoriesList.push({
          category: v,
          check: false
        }); return v
      })
      setCategoriesList(categoriesList)
    };


    // Objetivos unicos y pasarle el check a falso
    if (localStorage.getItem('filtro') !== null) {
      setDevelopmentList(filtroData?.data[3])

    } else {
      const developmentList = []
      const developmentM = productsFetch.productsData.map((x) => {
        return x.sustainabilityGoalDtoList.map((y) => {
          return y.name
        })
      })

      let dev2 = []
      for (let i = 0; i < developmentM.length; i++) {
        dev2 = dev2.concat(developmentM[i])
      }
      const uniqDevelopment = dev2.filter((elemento, index, self) => {
        return index === self.indexOf(elemento)
      }).map((v) => {
        developmentList.push({
          susDev: v,
          check: false
        }); return v
      })
      setDevelopmentList(developmentList)
    };



    // Paises unicos y pasarle el check a falso

    if (localStorage.getItem('filtro') !== null) {
      setCountriesList(filtroData?.data[2])

    } else {
      const countriesList = []
      productsFetch.productsData.map((x) => {
        return x.country
      }).filter((elemento, index, self) => {
        return index === self.indexOf(elemento)
      }).map((v) => {
        countriesList.push({
          country: v,
          check: false
        }); return v
      })
      setCountriesList(countriesList)
    }


    //Regiones unicas y pasarle el check a falso 
    if (localStorage.getItem('filtro') !== null) {
      setRegionList(filtroData?.data[4])

    } else {
      const regionList = []
      productsFetch.productsData.map((x) => {
        return x.region
      }).filter((elemento, index, self) => {
        return index === self.indexOf(elemento)
      }).map((v) => {
        regionList.push({
          region: v,
          check: false
        }); return v
      })
      setRegionList(regionList)
    };


    //Estados unicos y pasarle el check a falso
    if (localStorage.getItem('filtro') !== null) {
      setEstadosList(filtroData?.data[5])

    } else {
      const estadosList = [];
      productsFetch.productsData.map((x) => {
        return x.state
      }).filter((elemento, index, self) => {
        return index === self.indexOf(elemento)
      }).map((v) => {
        estadosList.push({
          state: v,
          check: false
        }); return v
      });
      setEstadosList(estadosList)
    };


    //Ciudades unicos y pasarle el check a falso 
    if (localStorage.getItem('filtro') !== null) {
      setCityList(filtroData?.data[6])

    } else {
      const cityList = [];
      productsFetch.productsData.map((x) => {
        return x.city
      }).filter((elemento, index, self) => {
        return index === self.indexOf(elemento)
      }).map((v) => {
        cityList.push({
          city: v,
          check: false
        }); return v
      })
      setCityList(cityList)
    };

  }, [productsFetch])




  //Aplicar el filtro con todas las listas
  const applyFilter = (data) => {
    
    if (data?.length !== 0) {
     
      updateTableLines(data)
      setCurrentPage(0);

    }

  };




  // Actulizar datos del filtrado y paginacion
  const updateTableLines = async (data) => {
 

    if (data === undefined) {
      data = [categoriesList, speciesList, countriesList, developmentList, regionList, estadosList, cityList];
   
    }

    

    let categories = data[0].filter(c => c.check).map(c => c.category);
    let forestType = data[1].filter(c => c.check).map(c => c.forestType);
    let country = data[2].filter(c => c.check).map(c => c.country);
    let development = data[3].filter(c => c.check).map(c => c.susDev);
    let region = data[4].filter(c => c.check).map(c => c.region);
    let state = data[5].filter(c => c.check).map(c => c.state);
    let city = data[6].filter(c => c.check).map(c => c.city);


    let filteredList = [...productsFetch.productsData];
    if (categories.length > 0) {
      categories.forEach((element, index) => {
        if (element === t("cat.noCategory")) {
          categories[index] = null;
        }
      });
      filteredList = filteredList.filter((c) => categories.includes(c.category));
    }

    if (forestType.length > 0) {
      filteredList = filteredList.filter((c) => forestType.includes(c.forestType));
    }

    if (country.length > 0) {
      filteredList = filteredList.filter((c) => country.includes(c.country));
    }

    if (development.length > 0) {
      filteredList = filteredList.filter((c) => {
        if (c.sustainabilityGoalDtoList.length > 0) {
          for (let i = 0; i < c.sustainabilityGoalDtoList.length; i++) {
            if (development.includes(c.sustainabilityGoalDtoList[i]["name"])) {
              return true
            }
          }
        }
        else {
          return false
        }
      })
    }

    if (region.length > 0) {
      filteredList = filteredList.filter((c) => region.includes(c.region));
    }

    if (state.length > 0) {
      filteredList = filteredList.filter((c) => state.includes(c.state));
    }

    if (city.length > 0) {
      filteredList = filteredList.filter((c) => city.includes(c.city));

    }


    if (orderSelectValue === 'pxAscendente') {
      filteredList = [...filteredList].sort((a, b) => {
        return a.price - b.price
      });
    } else if (orderSelectValue === 'pxDescendente') {
      filteredList = [...filteredList].sort((a, b) => {
        return b.price - a.price
      });
    } else if (orderSelectValue === 'QDescendente') {
      filteredList = [...filteredList].sort((a, b) => {
        return b.stockCredits - a.stockCredits
      });
    } else if (orderSelectValue === 'QAscendente') {
      filteredList = [...filteredList].sort((a, b) => {
        return a.stockCredits - b.stockCredits
      })
    }

    setProductsFilter(filteredList)
  };




  //PAGINACIÓN
  const [currentpage, setCurrentPage] = useState(0);
  const itemPage = 12;

  const [totalElement, setTotalElement] = useState(productsFilter.length)
  const [lastPage, setLastPage] = useState(Math.ceil((productsFilter.length) / itemPage) - 1)

  const [items, setItems] = useState([...productsFilter].splice(0, itemPage));





  useEffect(() => {
    setTotalElement(productsFilter.length);
    setLastPage(Math.ceil((productsFilter.length) / itemPage) - 1)
    setItems(productsFilter.slice(currentpage * itemPage, ((itemPage * currentpage) + itemPage)));
  }, [productsFilter, currentpage]);


  const nextHandler = () => {
    const nextpage = currentpage + 1;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;
    setCurrentPage(nextpage)
  }


  const prevHandler = () => {
    const prevPage = currentpage - 1;
    if (prevPage < 0) return;
    setCurrentPage(prevPage)
  }

  const firstHandler = () => {
    setCurrentPage(0)
  }

  const lastHandler = () => {
    setCurrentPage(lastPage)
  }

  const pointFirst = () => {
    const prevPage = currentpage - 2;
    if (prevPage < 0) return;
    setCurrentPage(prevPage)
  }

  const pointLast = () => {
    const nextpage = currentpage + 2;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;
    setCurrentPage(nextpage)
  }


  const handleSelectValue = (event) => {
    setOrderSelectValue(event.target.value);
  }


  let [showCatalogue, setShowCatalogue] = useState(true);

  const [showFilter, setShowFilter] = useState(true);
  const handleShowFilter = () => {
    setShowFilter(!showFilter)
    setShowCatalogue(true)
  }





  return (
    <div>
      <TemplatePage
        // className={'container'}
        padding={'p-cabecera'}
        id={'catalogoStart'}
        h1={t("meta.catalogo.title")}
        title={t("meta.catalogo.title")}
        desc={t("meta.catalogo.description")}
        image={metatag_image}>


        <div className='container'>

          {!productsFetch.isFetching && (productsFetch.productsData === undefined || productsFetch.productsData.length === 0) &&
            <div className='grid h-full	 place-items-center text-center text-airco2-filter-content ' style={{ minHeight: 'calc(100vh - 186px)' }}>

              <div >
                <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-blue-600" role="status">
                  <span className="visually-hidden">{t("account.orders.getOrders")}</span>
                </div>
                <p className='mt-2'>{t("cat.cargandoLargo")}</p>
              </div>
            </div>
          }



          {productsFetch.isFetching && (productsFetch.productsData !== undefined || productsFetch.productsData.length > 0) &&
            <div className='py-4  text-airco2-filter-content'>
              <button className='sm:hidden md:flex flecha-filter-pc font-semibold text-base mb-3' onClick={() => handleShowFilter()}>
                {t("filterCat.filtros")}
                <span className={`inline-block ${showFilter ? "rotate-180" : ""}`} >
                  <svg className="w-6 h-6 rounded fill-current" viewBox="0 0 32 32">
                    <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                  </svg>
                </span>
              </button>


              <div className={`flex flex-col    md:flex-col  xl:flex-row`}>
                <FiltroCatalogo
                  applyFilter={applyFilter}

                  speciesList={speciesList}
                  categoriesList={categoriesList}
                  countriesList={countriesList}
                  developmentList={developmentList}
                  regionList={regionList}
                  estadosList={estadosList}
                  cityList={cityList}
                  locationsC={countriesContext.countries}

                  setShowCatalogue={setShowCatalogue}
                  showFilter={showFilter}
                  setShowFilter={setShowFilter}
                />


                <div className='flex flex-column w-full md:pl-4'  >
                  {showCatalogue &&
                    <>
                      <Container fluid  >
                        <Row className='text-sm'>
                          <Col xs={12} md={6} className='p-0 text-center'>
                            {productsFetch.productsData.length > 0 &&
                              <div className=' md:text-left'>
                                <span>  {`${t("cat.mostrando")} 
                            ${totalElement} 
                            ${t("cat.de")} 
                            ${productsFetch.productsData.length}
                            ${t("cat.disponibles")}`}</span>
                              </div>
                            }

                          </Col>
                          <Col xs={12} md={6} className='p-0 text-center '>
                            {productsFetch.productsData.length > 0 &&
                              <div className=' block-inline  md:text-right'>

                                <span>
                                  {t("cat.ordenar")}
                                </span>

                                <span>
                                  <select onChange={handleSelectValue}
                                    defaultValue=""
                                    className=' btn-sm bg-white rounded-md text-sm ml-1'
                                    size={1} name='selectFilter'
                                  >
                                    <option value="" disabled="disabled"  >{t("cat.selecciona")}</option>
                                    <option value="pxAscendente"> {t("cat.pAscendente")}</option>
                                    <option value="pxDescendente"> {t("cat.pDescendente")}</option>
                                    <option value="QAscendente"> {t("cat.cAscendente")}</option>
                                    <option value="QDescendente"> {t("cat.cDescendente")}</option>
                                  </select>
                                </span>
                              </div>
                            }
                          </Col>

                        </Row>
                      </Container>


                      <div className='f-containter-content w-full'  >
                        <div className='container-content' >
                          <div className='container-card-market' >
                            {
                              productsFetch.productsData === undefined ? "cargando..."

                                :

                                totalElement === 0 && productsFetch.isFetching === true ?
                                  <div className='flex flex-column w-full mt-4 text-center'>
                                    <p>{t("cat.noProducts")}</p>
                                    <img className='mt-4' alt="no encuentra" src={search}></img>
                                  </div>
                                  :
                                  <CardCatalogo
                                    products={items}
                                    currentpage={currentpage}
                                  />
                            }
                          </div>
                        </div>
                      </div>
                      {(productsFetch.productsData.length > 0) &&
                        <div className="mt-6 mb-6">
                          <PaginationClassic
                            itemPage={itemPage}
                            currentpage={currentpage}
                            items={items}
                            nextHandler={nextHandler}
                            prevHandler={prevHandler}
                            total={productsFilter}
                            firstHandler={firstHandler}
                            lastHandler={lastHandler}
                            lastPage={lastPage}
                            pointFirst={pointFirst}
                            pointLast={pointLast}
                          />
                        </div>
                      }
                    </>
                  }
                </div>
              </div>

            </div>

          }

        </div>

        <BannerForest></BannerForest>


      </TemplatePage >

    </div>

  )
}