import { formatThousands } from '../../../utils/Utils'
import arbol from '../../../images/arbol.jpeg'
import circle from '../../../images/location.svg'
import owner from '../../../images/userProvider.svg'
import forest from '../../../images/iconTreesVerde.svg';
import { productUrl } from '../../Utils';
import { providerUrl } from '../../Utils';

function ListaPedidosLineItem({ itemOrder }) {

    return (<tbody >
        <tr>
            <td>
                <div>
                    <img className='w-40 h-28' src={itemOrder.product.productImageDtoList.length > 0 ? itemOrder.product.productImageDtoList.find(i => i.principal).path : arbol} alt="product"></img>

                </div>
            </td>


            <td>
                <div>
                    <b>
                        <a href={productUrl(itemOrder.product)} target={"_blank"} rel="noreferrer" className='text-airco2-general'>
                            {itemOrder.product.name}
                        </a>
                    </b>
                </div>
            </td>

            <td>
                <div>
                    <a href={`https://maps.google.com/?q=${itemOrder.product.latitude},${itemOrder.product.longitude}`} aria-label='redirige a googleMaps' target='_blank' rel='noopener noreferrer' className='text-airco2-general'>
                        <span className='flex gap-1'>
                            <img alt='circulo-icono' src={circle} width={'14px'} ></img>
                            {itemOrder.product.city}, {itemOrder.product.state}
                        </span>
                    </a>
                </div>
            </td>
            <td>
                <div>
                    <span className='flex gap-1'>
                        <img alt='circulo-icono' src={forest} width={'14px'} ></img>
                        {itemOrder.product.forestType}
                    </span>
                </div>
            </td>
            <td>
                <div>
                    <a href={providerUrl(itemOrder.product)} target={"_blank"} rel="noreferrer" className='text-airco2-general'>
                        <span className='flex gap-1'>
                            <img alt='circulo-icono' src={owner} width={'14px'} ></img>
                            {itemOrder.product.provider}
                        </span>
                    </a>
                </div>
            </td>

            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-right">
                <div>{formatThousands(itemOrder.quantity)} t</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-right">
                <div>{formatThousands(itemOrder.unitPrice)} €</div>
            </td>

            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-right">
                {itemOrder.urlInvoiceForestStripe !== null && itemOrder.urlInvoiceForestStripe !== "" &&
                    <a href={itemOrder.urlInvoiceForestStripe} target='_blank' className='inline-flex' data-toggle="tooltip" data-placement="left" rel='noreferrer' title="Descargar factura forestal">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-invoice" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                            <line x1="9" y1="7" x2="10" y2="7" />
                            <line x1="9" y1="13" x2="15" y2="13" />
                            <line x1="13" y1="17" x2="15" y2="17" />
                        </svg></a>
                }
            </td>
        </tr>
        <tr>
            <td colspan="12"><a className="text-md text-airco2-filter-content flex justify-end" href={`${process.env.REACT_APP_WEB_DOMAIN}/faqs#invoices`} target='_blank'>¿Por qué hay más de una factura por pedido? *</a></td>
        </tr>
    </tbody>)

}

export default ListaPedidosLineItem;