
import Sidebar from '../../components/account/Sidebar';
import AddressList from '../../components/account/address/AddressList';
import TemplatePage from '../TemplatePage';
import { useTranslation } from 'react-i18next';

function Address() {
    const { t } = useTranslation();
    return (
        < div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden " style={{ backgroundColor: '#ebebe9' }} >
            <TemplatePage
                padding={'pt-[16vh]'}
                title={t("meta.address.title")}
            >


                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                    {/* Content */}
                    <div className="container bg-white shadow-lg rounded-sm mb-8">
                        <div className="flex flex-col md:flex-row md:-mr-px">
                            <Sidebar></Sidebar>
                            <AddressList />
                        </div>
                    </div>
                </div>


            </TemplatePage>
        </div >
    );
}

export default Address;