import axios from "axios";
import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { LayerGroup, LayersControl, MapContainer, TileLayer, WMSTileLayer } from "react-leaflet";
import MarkerLayer from '../components/MarkerLayer';
import { useCategoriesContext } from "../context/categoriesContext";
import metatag_image from './../images/Slide01.webp';
import TemplatePage from "./TemplatePage";
import MarkerClusterGroup from "react-leaflet-cluster";

// const position = [51.505, -0.09]
const position = [40.4165, -3.70256]

const Map = () => {
  const { t } = useTranslation();


  const categoriesContext = useCategoriesContext();

  // FETCH para categorias
  const [categories, setCategories] = useState({
    categoriesData: categoriesContext.categories,
    isFetching: false
  });

  const [productsF, setProductsf] = useState({
    productsData: [],
    isFetching: false
  });


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setProductsf({ productsData: productsF.productsData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_MP_PRODUCTS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
            // 'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setProductsf({ productsData: response.data, isFetching: false });
      } catch (exception) {
        setProductsf({ productsData: productsF.productsData, isFetching: false });
      }
    };
    fetchProducts();
  }, []);

  const products = productsF.productsData;




  const allCategories = categories.categoriesData;


  const categoriesArray = [];
  const uncategorizedProducts = [];

  for (let i = 0; i < allCategories.length; i++) {
    const category = allCategories[i];
    const productsByCategory = products.filter(prod => prod.category === category.name);
    categoriesArray.push(productsByCategory);
  }

  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    if (!product.category) {
      uncategorizedProducts.push(product);
    }
  }

  categoriesArray.push(uncategorizedProducts);

  const productsByCategoryArray = categoriesArray;


  return (
    <div>
      <TemplatePage
        padding={'pt-header'}
        h1={t("meta.map.title")}
        title={t("meta.map.title")}
        desc={t("meta.map.description")}
        image={metatag_image}>


        <MapContainer center={position} zoom={6} scrollWheelZoom={true} minZoom={3} style={{ zIndex: 0 }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {products.length > 0 ?
            <LayersControl position="topright">
              <LayersControl.BaseLayer name="Normal" checked>
                <LayerGroup>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    maxZoom="20"
                  />
                </LayerGroup>
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satélite">
                <LayerGroup>
                  <TileLayer
                    attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                    url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                    maxZoom="20"
                  />
                </LayerGroup>
              </LayersControl.BaseLayer>

              <LayersControl.Overlay name="Ver catastro">
                <LayerGroup>
                  <WMSTileLayer
                    url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
                    format='image/png'
                    transparent="false"
                    minZoom="14"
                    maxZoom="20"
                    opacity="0.4"
                  />
                </LayerGroup>
              </LayersControl.Overlay>

              {productsByCategoryArray.map((v) => {
                return (
                  <LayersControl.Overlay checked name={v[0].category === null ? 'Sin Categoría' : v[0].category}>
                    <LayerGroup>
                    <MarkerClusterGroup 
                      chunkedLoading 
                      showCoverageOnHover={false}
                      maxClusterRadius={200}
                      spiderfyOnMaxZoom={true}
                      >
                      <MarkerLayer productsData={v} categories={categories} />
                      </MarkerClusterGroup>
                    </LayerGroup>
                  </LayersControl.Overlay>
                )
              })
              }
            </LayersControl>
            :
            ""

          }
        </MapContainer>

      </TemplatePage>
    </div>
  )

};

export default Map;
