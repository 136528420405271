import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';
import gl from '../images/es-ga.svg';
import es from '../images/es.svg';
import en from '../images/gb.svg';
import logo from "../images/logo2.png";
import Avatar from '../images/avatar3.svg';


import pt from '../images/pt.svg';
import i18n from '../translations/i18n';
import ModalCarrito from './ModalCarrito'
import { NavLink } from 'react-router-dom';
import { handleClick } from './Utils';
function
  Header() {
  const auth = useAuthContext();

  // change the language
  const { t } = useTranslation();
  const lng = i18n.language;
  const [languages, setLanguages] = useState([])

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    setLanguages([
      {
        "lang": "es",
        "name": "Español",
        "active": lng.includes("es") ? true : false,
        "image": es
      },
      {
        "lang": "gl",
        "name": "Galego",
        "active": lng === "gl" ? true : false,
        "image": gl
      },
      {
        "lang": "pt",
        "name": "Português",
        "active": lng === "pt" ? true : false,
        "image": pt
      },
      {
        "lang": "en",
        "name": "English",
        "active": lng === "en" ? true : false,
        "image": en
      },

    ]);
  }, [lng]);


  const menuItems = [{
    "name": t("header.home"),
    "url": "/"
  },
  {
    "name": t("header.catalogo"),
    "url": "/catalogo"
  },
  {
    "name": t("header.mapa"),
    "url": "/map"
  },
  {
    "name": t("header.registroProyectos"),
    "url": "/forest"
  },
  {
    "name": t("header.conocenos"),
    "url": "https://airco2.earth"
  },
  {
    "name": "Blog",
    "url": "https://airco2-25435066.hubspotpagebuilder.eu/"
  }
  ]


  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!target.classList.contains("navbar-backdrop")) return;
      document.getElementsByName("responsive-menu-button")[0].classList.add('hidden');
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return;
      document.getElementsByName("responsive-menu-button")[0].classList.add('hidden');

    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });



  const openDropdown = (e) => {
    let name = e.target.attributes.getNamedItem('data-id').nodeValue;
    const dropdownMenu = document.getElementsByName(name)[0];
    document.getElementsByName(name)[0].classList.toggle('hidden');

    if (name.includes("language")) {
      document.getElementById("language-down").classList.toggle('hidden');
      document.getElementById("language-up").classList.toggle('hidden');
    }

    const backdrop = document.querySelector(".navbar-transition");

    if (!dropdownMenu.classList.contains('hidden')) {
      backdrop.style.transform = "translateX(-100%)";
      setTimeout(() => {
        backdrop.style.transition = "transform 0.5s ease";
        backdrop.style.transform = "translateX(0)";
      }, 100);
    }
    else {
      backdrop.style.transition = "transform 0.5s ease";
      backdrop.style.transform = "translateX(-100%)";
      setTimeout(() => {
        backdrop.style.transition = "";
      }, 500);
    }

  }

  return (
    <div>
      {/* <div className="fixed inset-x-0 top-0 z-50 h-0.5 mt-0.5     bg-blue-500"></div> */}
      {/* 
      <nav className=" bg-white/100     backdrop-blur-md shadow-md w-full    fixed top-0 left-0 right-0 z-10"> */}
      <nav className="bg-airco2-principalHeaderFooter w-full    fixed top-0 left-0 right-0 z-10" style={{ zIndex: 9999 }}>
        <div className="container flex flex-wrap items-center justify-between mx-auto py-4    ">
          <Link onClick={handleClick} className="text-3xl font-bold leading-none" to="/" >
            <img src={logo} alt="AirCO" className="h-10" width={'120px'} />
          </Link>

          <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">

            {menuItems.map(i => {
              return <li key={"menu" + i.name}>
                {i.url.includes("http") &&
                  <a className=" text-arico2-footerHeader " href={i.url} target={"_blank"} rel='noopener noreferrer' >{i.name} </a>
                }
                {!i.url.includes("http") &&
                  <NavLink onClick={handleClick} className=" text-arico2-footerHeader 	" to={i.url}>  {i.name} </NavLink>
                }

              </li>
            })}


          </ul>
          <ul className="flex 	items-center">

            <li>
              <div className="relative inline-block text-left">
                <div>
                  {/* IDIOMA */}
                  {/* <button type="button" className="inline-flex w-full justify-center   font-medium text-gray-700 "
                    aria-expanded="true" aria-haspopup="true"
                  >
                    {languages.map(l => {
                      if (l.active) {
                        return <span data-id="language-menu-button" key={"lang-sel" + l.lang} aria-expanded="true" aria-haspopup="true" className='text-arico2-footerHeader hover:text-gray-500 font-bold'
                          onClick={(e) => openDropdown(e)}>
                          <img className="w-4 h-4  inline mr-1 " alt='imgName' style={{ marginBottom: "2px", pointerEvents: "none" }} src={l.image} />
                          <span className=' hidden sm:inline' style={{ pointerEvents: "none" }}>  {l.name}</span>
                          <svg id="language-down" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down inline" viewBox="0 0 16 16" pointerEvents="none">
                            <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                          </svg>

                          <svg id="language-up" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up inline hidden" viewBox="0 0 16 16" pointerEvents="none">
                            <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                          </svg>
                        </span>
                      } return ""
                    })}

                  </button> */}
                </div>


                <div className="absolute hidden right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu" aria-orientation="vertical" name="language-menu-button" tabIndex="-1">
                  <div className="py-1" role="none">

                    {languages.map(l => {
                      return <a href=" " key={"lang-" + l.lang} onClick={() => changeLanguage(l.lang)} className={`text-airco2-filter-content   block px-4 py-2 ${l.active && "hidden"}`} role="menuitem" tabIndex="-1" id="menu-item-0">
                        <img className="w-4 h-4  inline mr-3 " alt='imgLanguage' src={l.image} />
                        {l.name}</a>
                    })}


                  </div>
                </div>
              </div>

            </li>

            {!auth.isLoggedIn() &&
              <li className='ml-4'>
                <Link onClick={handleClick} className="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6   text-arico2-HeaderAuth   transition duration-200" to="/register">{t("header.register")}</Link>
                <Link onClick={handleClick} className="hidden lg:inline-block py-2 px-6 bg-airco2-secondary   text-arico2-HeaderAuth rounded-md transition duration-200"
                  to={{
                    pathname: "/login",
                    search: "?originUrl=" + window.location.pathname,
                  }}>{t("header.login")}</Link>
              </li>
            }

            {auth.isLoggedIn() &&
              <>
                <li>
                  <div className='pr-3 md:pl-5'>
                    <ModalCarrito align="right" />
                  </div>
                </li>
                <li>
                  <div className=" text-left">
                    <div>
                      <button type="button" className="flex mr-3   rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                        aria-expanded="true" aria-haspopup="true"

                      >
                        <span className="sr-only">Open user menu</span>
                        <img className="w-8 h-8 rounded-full text-arico2-footerHeader" src={Avatar} alt="user pic"
                          data-id="user-menu-button"
                          onClick={(e) => openDropdown(e)} />

                      </button>
                    </div>

                    <div className="absolute hidden right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu" aria-orientation="vertical" name="user-menu-button" tabIndex="-1">
                      <div className="px-4 py-3 border-solid border-b border-gray-200">
                        {auth.getName().includes('undefined') ?
                          ""
                          :
                          <span className="block  text-airco2-general ">{auth.getName()}</span>
                        }

                        <span className="block  text-airco2-filter-content truncate ">{auth.getUsername()}</span>

                      </div>
                      <ul className="py-2" aria-labelledby="user-menu-button">
                        <li>
                          <Link onClick={handleClick} to="/account/profile" className="block px-4 py-2 text-airco2-filter-content">{t("header.account")}</Link>
                        </li>

                        <li>
                          <Link className="block  px-4 py-2 text-airco2-filter-content" to="# " onClick={() => auth.doLogout()}>
                            {t("header.cerrarsesion")}
                          </Link>

                        </li>
                      </ul>
                    </div>
                  </div>
                </li>


              </ >
            }


            <li>
              <div className="lg:hidden">
                <button className="navbar-burger flex items-center text-arico2-footerHeader p-3"
                  data-id="responsive-menu-button"
                  onClick={(e) => openDropdown(e)}>
                  <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" pointerEvents="none">
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                  </svg>
                </button>
              </div>
            </li>
          </ul>
        </div>

      </nav >
      {/* Responsive menú */}
      < div className="navbar-menu relative z-50 hidden"
        name="responsive-menu-button">
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25">

        </div>
        <nav className="navbar-transition fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            {/* <Link onClick={handleClick} className="mr-auto text-3xl font-bold leading-none" to="/">
              <img src={logo} alt="AirCO" className="h-10" style={{ filter: "contrast(0)" }} />
            </Link> */}

            <button className="navbar-close" data-id="responsive-menu-button"
              onClick={(e) => openDropdown(e)}>
              <svg className="h-6 w-6 text-airco2-filter-content cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" pointerEvents="none">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              {menuItems.map(i => {
                return <li className="mb-1" key={"menuresp-" + i.name}>
                  {i.url.includes("http") &&
                    <a className="block p-4  font-semibold text-airco2-filter-content hover:bg-blue-50 hover:text-blue-600 rounded" href={i.url} target={"_blank"} rel='noopener noreferrer'>{i.name} </a>
                  }
                  {!i.url.includes("http") &&
                    <NavLink onClick={handleClick} className="block p-4  font-semibold text-airco2-filter-content hover:bg-blue-50 hover:text-blue-600 rounded" to={i.url}>  {i.name} </NavLink>
                  }

                </li>
              })}

            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              {!auth.isLoggedIn() &&
                <>
                  <Link onClick={handleClick} className="block px-4 py-3 mb-3 leading-loose text-xs text-center text-airco2-filter-content font-semibold rounded-md" to="/register">{t("header.register")}</Link>
                  <Link onClick={handleClick} className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold  bg-airco2-secondary  rounded-md"
                    to={{
                      pathname: "/login",
                      search: "?originUrl=" + window.location.pathname,
                    }}>{t("header.login")}</Link>
                </>
              }
            </div>
            <p className="my-4 text-xs text-center text-airco2-filter-content">
              <span>{t("header.signature")}{new Date().getFullYear()}</span>
            </p>
          </div>
        </nav>
      </div >
    </div >

  );
}

export default Header;
